import React from 'react';
import '../App.css';
import ResourceTile from '../components/ResourceTile';
import BookTile from '../components/BookTile';
function Resources() {

      const spectro = {
        name: 'Spectro',
        description: 'A manual for the second order perturbation theory treatment of the vibrational and rotational spectroscopic values (1994).'
      }
      const unix = {
        name: 'Unix Guide',
        description: 'A manual for getting started with the unix command-line interface within the astrochemistry research group at Ole Miss'
      }
      const INTDER05 = {
        name: 'INTDER05',
        description: `A manual for INTDER2005, which is a general program developed by Wesley D. Allen and co-workers which performs various vibrational analyses and higher-order nonlinear transformations among force field representations`
      }
      const BOOK = {
        name: 'Complete Science Communication: A Guide to Connecting with Scientists, Journalists and the Public',
        description: 'Complete Science Communication focusses on four major aspects of science communication: writing for non-technical audiences and science journalism; writing for technical audiences and peer-reviewed journal writing; public speaking of science; and public relations.',
        link: `https://www.amazon.com/Complete-Science-Communication-Connecting-Journalists-ebook/dp/B07KV26J1K`
      }

      return (
        <div className="background-container">
            <h1 style={{ color:"white", margin: 30 }}>Resources</h1>

            <div className="grid-container-resources">

            <ResourceTile
              resource={spectro}
            />

            <ResourceTile
              resource={INTDER05}
            />
            <ResourceTile
              resource={unix}
            />

            <BookTile
              resource={BOOK}
            />

            </div>

        </div>
      )
    }


export default Resources;
