import React, { useEffect, useState } from 'react';
import '../App.css';
import CardFlip from '../components/CardFlip';
import {isMobile} from 'react-device-detect';
import axios from 'axios';

// These article covers are the incorrect ones. Use the alternate cover by clicking on the ACS cover in the top right.

function Publications() {
    const [ publications, setPublications] = useState([])
    useEffect(()=> {
        axios.get('https://r410berry.com/api/getCV').then(response => {
        //axios.get('https://r410berry.com/api/getCV').then(response => {
        //axios.get('http://127.0.0.1:5000/api/getCV').then(response => {
            console.log("SUCCESS", response.data)
            setPublications(response.data)
        }).catch(error => {
            console.log(error)
        })
    }, [])
    console.log(publications)        
  
    const publicationView = (pubs) => {
        const display = pubs.map((line, index) =>
        <>
            <div className="publication-list-individual-container">
                <h1>{index+1}</h1>
                <div>
                    <p>{line}</p>
                    
                    {/*{(line.split("DOI:")).length > 1 ? (line.split("DOI:")[1]).slice(-1) === '.' ? <a 
                            href={`https://dx.doi.org/${line.split("DOI:")[1].slice(0,-1)}`}
                            > {line.split("DOI:")[1].slice(0,-1)}</a> :  <a 
                            href={`https://dx.doi.org/${line.split("DOI:")[1]}`}
                            > {line.split("DOI:")[1]}</a>  : null}
    */}
                </div>
            </div>
        </>);
        return (
            display
        )
    }
  if (isMobile) {
    return (
        <div className="background-container">
            <h1 style={{ fontSize: 40, color: "white"}}>Publications</h1>
            
            <div className="cardflip-big-container-mobile">
             <CardFlip
                imageURI={`https://pubs.acs.org/pb-assets/images/_journalCovers/jpcafh/jpcafh_v125i021-2.jpg?0.2794687563972922`} 
                title="Linear and Helical Carbonic Acid Clusters"
                authors="Austin M. Wallace and Ryan C. Fortenberry*"
                abstract="Crystallization of carbonic acid likely begins with a linear or ribbon-esque oligomerization, but a helical spiral is shown here to be a new, competing motif for this process. The present combined density functional theory and coupled-cluster theory work examines both the ribbon and the new helical spiral motifs in terms of relative energies, sequential binding energies, and electronic spectra which could potentially aid in distinguishing between the two forms. The helix diverges in energy from the ribbon by roughly 0.2 eV (∼4 kcal/mol) per dimer addition, but the largest intensity absorption features at 9.16 eV (135 nm) and 7.11 eV (175 nm), respective of the ribbon and spiral, will allow these to be separately observed and classified via electronic spectroscopy to determine more conclusively which motif holds in the earliest formation stages of solid carbonic acid."
                doi='https://doi.org/10.1021/acs.jpca.1c02878'
            />
            {/*
            <CardFlip
                imageURI={`https://pubs.acs.org/na101/home/literatum/publisher/achs/journals/content/jpcafh/2020/jpcafh.2020.124.issue-42/jpcafh.2020.124.issue-42/20201022/jpcafh.2020.124.issue-42.largecover-2.jpg`} 
                title="Anharmonic Frequencies and Spectroscopic Constants of OAlOH and AlOH: Strong Bonding but Unhindered Motion"
                authors="Ryan C. Fortenberry*, Tarek Trabelsi, and Joseph S. Francisco"
                abstract="The astrophysical buildup of premineral nanocrystals from atoms to the smallest network-covalent solids will require observations of various small molecules containing the most common elements in minerals including aluminum and oxygen. The present work utilizes high-level quantum chemical quartic force field (QFF) approaches to produce anharmonic vibrational frequencies and spectroscopic constants for such species. The computed Beff for the astrochemically known AlOH molecule at 15780.5 MHz is a mere 40 MHz above the experimental value implying that the Beff for OAlOH at 5580.9 MHz is similarly accurate. The additional 7.31 D dipole moment in OAlOH implies that this molecule is a viable target for interstellar observation. Unlike the other anharmonic vibrational frequencies reported in this work, the Al–O–H bending frequencies in both AlOH and OAlOH are poorly described in the present QFF results. However, this failing actually highlights the fact that these bends are exceptionally floppy yet with counterintuitive exceedingly strong bonding. The Al–O bond energies are 128.2 and 107.2 kcal/mol, respective of AlOH and OAlOH, while the barriers to linearity are meager 16.6 and 380.7 cm–1 (0.1 and 1.1 kcal/mol)."
                doi='https://doi.org/10.1021/acs.jpca.0c07945'
            />
            */}
             <CardFlip
                imageURI={`https://pubs.acs.org/na101/home/literatum/publisher/achs/journals/content/aesccq/2020/aesccq.2020.4.issue-6/aesccq.2020.4.issue-6/20200618/aesccq.2020.4.issue-6.largecover-2.jpg`} 
                title="Coincidence between Bond Strength, Atomic Abundance, and the Composition of Rocky Materials"
                authors="Edmund S. Doerksen and Ryan C. Fortenberry"
                abstract="The astrophysical detection of chloromethane indicates that a low-energy form of the cation, the methyl chloronium cation (CH2ClH+), may also be found in similar regions. Quantum chemical computations are providing what have been previously benchmarked as reliable vibrational frequencies and spectroscopic constants for this open-shell cation. The ν3 Cl–H stretch at 2630.5 cm–1 has a relatively large intensity and sits at 3.80 μm in a fairly unpopulated region of most astronomical infrared spectra, where it could more readily be distinguished from polycyclic aromatic hydrocarbons or other complex organic species. The 1.48 D center-of-mass computed dipole moment of CH2ClH+ makes it observable rotationally, and its differences in structure and rotational constants from chloromethane will distinguish this cation isomer from its neutral counterpart. Such would have application for observational analysis with either ground-based radio telescopes or from the upcoming James Webb Space Telescope."
                doi='https://pubs.acs.org/doi/10.1021/acsearthspacechem.0c00029'
            />
        
            <CardFlip
                imageURI={`https://chemistry-europe.onlinelibrary.wiley.com/cms/asset/100ec8ee-9467-4940-95a4-22349f2802a4/cphc202000552-toc-0001-m.jpg`} 
                title="On the Formation of the Popcorn Flavorant 2,3‐Butanedione (CH3COCOCH3) in Acetaldehyde‐Containing Interstellar Ices"
                authors="Dr. N. Fabian Kleimeier  Dr. Andrew M. Turner  Prof. Dr. Ryan C. Fortenberry  Prof. Dr. Ralf I. Kaiser"
                abstract="Acetaldehyde (CH3CHO) is ubiquitous throughout the interstellar medium and has been observed in cold molecular clouds, star forming regions, and in meteorites such as Murchison. As the simplest methyl‐bearing aldehyde, acetaldehyde constitutes a critical precursor to prebiotic molecules such as the sugar deoxyribose and amino acids via the Strecker synthesis. In this study, we reveal the first laboratory detection of 2,3‐butanedione (diacetyl, CH3COCOCH3) – a butter and popcorn flavorant – synthesized within acetaldehyde‐based interstellar analog ices exposed to ionizing radiation at 5 K. Detailed isotopic substitution experiments combined with tunable vacuum ultraviolet (VUV) photoionization of the subliming molecules demonstrate that 2,3‐butanedione is formed predominantly via the barrier‐less radical–radical reaction of two acetyl radicals (CH3ĊO). These processes are of fundamental importance for a detailed understanding of how complex organic molecules (COMs) are synthesized in deep space thus constraining the molecular structures and complexity of molecules forming in extraterrestrial ices containing acetaldehyde through a vigorous galactic cosmic ray driven non‐equilibrium chemistry."
                doi='https://doi.org/10.1002/cphc.202000116'
            />
             <CardFlip
                imageURI={`https://pubs.acs.org/na101/home/literatum/publisher/achs/journals/content/jpcafh/2020/jpcafh.2020.124.issue-16/jpcafh.2020.124.issue-16/20200423/jpcafh.2020.124.issue-16.largecover.jpg`} 
                title="Anharmonic Frequencies of (MO)2 and Related Hydrides for M = Mg, Al, Si, P, S, Ca, and Ti and Heuristics for Predicting Anharmonic Corrections of Inorganic Oxides"
                authors="Brent R. Westbrook and Ryan C. Fortenberry*"
                abstract="The low-frequency vibrational fundamentals of D2h inorganic oxides are readily modeled by heuristic scaling factors at fractions of the computational cost compared to explicit anharmonic frequency computations. Oxygen and the other elements in the present study are abundant in geochemical environments and have the potential to aggregate into minerals in planet-forming regions or in the remnants of supernovae. Explicit quartic force field computations at the CCSD(T)-F12b/cc-pVTZ-F12 level of theory generate scaling factors that accurately predict the anharmonic frequencies with an average error of less than 1.0 cm–1 for both the metal–oxygen stretching frequencies and the torsion and antisymmetric stretching frequencies. Inclusion of hydrogen motions is less absolutely accurate but is similarly relatively predictive. The fundamental vibrational frequencies for the seven tetra-atomic inorganic oxides examined presently fall below 876 cm–1 and most of the hydrogenated species do as well. Additionally, ν6 for the SiO dimer is shown to have an intensity of 562 km mol–1, with each of the other molecules having one or more frequencies with intensities greater than 80 km mol–1, again with most in the low-frequency infrared range. These intensities and the frequencies computed in the present study should assist in laboratory characterization and potential interstellar or circumstellar observation."
                doi='https://doi.org/10.1021/acs.jpca.0c01609'
            />

            <CardFlip
                imageURI={`https://pubs.acs.org/na101/home/literatum/publisher/achs/journals/content/jpcafh/2018/jpcafh.2018.122.issue-35/jpcafh.2018.122.issue-35/20180906/jpcafh.2018.122.issue-35.largecover.jpg`} 
                title="Rovibrational Considerations for the Monomers and Dimers of Magnesium Hydride and Magnesium Fluoride"
                authors="C. Zachary Palmer and Ryan C. Fortenberry"
                abstract="Magnesium is an understudied chemical element that is quite useful in materials science and may be an essential astrochemical building block for grain formation in proto-planetary disks. This work provides quantum chemical prediction for the vibrational and rovibrational spectra of the structurally similar magnesium hydride and magnesium fluoride monomers and dimers. Magnesium fluoride is commonly utilized in infrared-observing windows and is a known terrestrial mineral, sellaite. Magnesium hydride is likely to exist in various astrophysical environments. Comparison of the anharmonic quantum chemical spectral data computed in this work to known gas phase values for MgH2 is excellent with the computed 1584.1 cm–1 antisymmetric Mg–H stretch less than 5 cm–1 below experiment for example. The condensed phase vibrational attributions of the dimer, however, are less comparable with the present results potentially indicating that some of the previous assignments may need to be revisited. The magnesium fluoride monomer and dimer have no previous vibrational experimental results reported, and the work here should be solid predictions as to their spectral features for comparison either to laboratory work or potentially even to interstellar observations."
                doi='https://doi.org/10.1021/acs.jpca.8b06611'
            />
             <CardFlip
                imageURI={`https://onlinelibrary.wiley.com/cms/asset/d5f016d7-ad76-4902-8d12-1df76877d423/qua25238-toc-0001-m.jpg`} 
                title="Quantum astrochemical spectroscopy"
                authors="Ryan C. Fortenberry"
                abstract="In this review, the origins of astrochemistry and the initial applications of quantum chemistry to the discovery of new molecules in space are discussed. Furthermore, more recent successes and failures of quantum astrochemistry are explored. Finally, the application of quantum chemistry to the chemical study of space is driving developments in large‐scale computational science. Consequently, cloud computing and large molecule computations are discussed. Astrochemistry is a natural application of quantum chemistry. The ability to analyze routinely and completely the structural, spectroscopic, and electronic properties of any given molecule, regardless of its laboratory stability, make this tool a necessary component for astrochemical analysis. The sizes of the computations scaling with the number of electrons and degrees‐of‐freedom can become limiting, but proper choices of methods can provide unique insights. The chemistry of the Earth is a small snapshot of the chemistries available in the universe at large, and the flexibility inherent within computation make quantum chemistry an excellent driver of new knowledge in fundamental molecular science as well as in astrophysics. © 2016 Wiley Periodicals, Inc."
                doi='https://doi.org/10.1002/qua.25180'
            />
            </div>
            <div>
                {publicationView(publications)}
            </div>
        </div>
    )
  } else {
    return ( 
        <div className="background-container">
            <h1 style={{fontSize: 40, color: 'white', margin: 30 }}>Publications</h1>
            
            <div className="cardflip-big-container">
             <CardFlip
                imageURI={`https://pubs.acs.org/pb-assets/images/_journalCovers/jpcafh/jpcafh_v125i021-2.jpg?0.2794687563972922`} 
                title="Linear and Helical Carbonic Acid Clusters"
                authors="Austin M. Wallace and Ryan C. Fortenberry*"
                abstract="Crystallization of carbonic acid likely begins with a linear or ribbon-esque oligomerization, but a helical spiral is shown here to be a new, competing motif for this process. The present combined density functional theory and coupled-cluster theory work examines both the ribbon and the new helical spiral motifs in terms of relative energies, sequential binding energies, and electronic spectra which could potentially aid in distinguishing between the two forms. The helix diverges in energy from the ribbon by roughly 0.2 eV (∼4 kcal/mol) per dimer addition, but the largest intensity absorption features at 9.16 eV (135 nm) and 7.11 eV (175 nm), respective of the ribbon and spiral, will allow these to be separately observed and classified via electronic spectroscopy to determine more conclusively which motif holds in the earliest formation stages of solid carbonic acid."
                doi='https://doi.org/10.1021/acs.jpca.1c02878'
            />
            {/*
            <CardFlip
                imageURI={`https://pubs.acs.org/na101/home/literatum/publisher/achs/journals/content/jpcafh/2020/jpcafh.2020.124.issue-42/jpcafh.2020.124.issue-42/20201022/jpcafh.2020.124.issue-42.largecover-2.jpg`} 
                title="Anharmonic Frequencies and Spectroscopic Constants of OAlOH and AlOH: Strong Bonding but Unhindered Motion"
                authors="Ryan C. Fortenberry*, Tarek Trabelsi, and Joseph S. Francisco"
                abstract="The astrophysical buildup of premineral nanocrystals from atoms to the smallest network-covalent solids will require observations of various small molecules containing the most common elements in minerals including aluminum and oxygen. The present work utilizes high-level quantum chemical quartic force field (QFF) approaches to produce anharmonic vibrational frequencies and spectroscopic constants for such species. The computed Beff for the astrochemically known AlOH molecule at 15780.5 MHz is a mere 40 MHz above the experimental value implying that the Beff for OAlOH at 5580.9 MHz is similarly accurate. The additional 7.31 D dipole moment in OAlOH implies that this molecule is a viable target for interstellar observation. Unlike the other anharmonic vibrational frequencies reported in this work, the Al–O–H bending frequencies in both AlOH and OAlOH are poorly described in the present QFF results. However, this failing actually highlights the fact that these bends are exceptionally floppy yet with counterintuitive exceedingly strong bonding. The Al–O bond energies are 128.2 and 107.2 kcal/mol, respective of AlOH and OAlOH, while the barriers to linearity are meager 16.6 and 380.7 cm–1 (0.1 and 1.1 kcal/mol)."
                doi='https://doi.org/10.1021/acs.jpca.0c07945'
            />
            */}
            <CardFlip
                imageURI={`https://pubs.acs.org/na101/home/literatum/publisher/achs/journals/content/aesccq/2020/aesccq.2020.4.issue-6/aesccq.2020.4.issue-6/20200618/aesccq.2020.4.issue-6.largecover-2.jpg`} 
                title="Coincidence between Bond Strength, Atomic Abundance, and the Composition of Rocky Materials"
                authors="Edmund S. Doerksen and Ryan C. Fortenberry"
                abstract="The astrophysical detection of chloromethane indicates that a low-energy form of the cation, the methyl chloronium cation (CH2ClH+), may also be found in similar regions. Quantum chemical computations are providing what have been previously benchmarked as reliable vibrational frequencies and spectroscopic constants for this open-shell cation. The ν3 Cl–H stretch at 2630.5 cm–1 has a relatively large intensity and sits at 3.80 μm in a fairly unpopulated region of most astronomical infrared spectra, where it could more readily be distinguished from polycyclic aromatic hydrocarbons or other complex organic species. The 1.48 D center-of-mass computed dipole moment of CH2ClH+ makes it observable rotationally, and its differences in structure and rotational constants from chloromethane will distinguish this cation isomer from its neutral counterpart. Such would have application for observational analysis with either ground-based radio telescopes or from the upcoming James Webb Space Telescope."
                doi='https://pubs.acs.org/doi/10.1021/acsearthspacechem.0c00029'
            />
            <CardFlip
                imageURI={`https://chemistry-europe.onlinelibrary.wiley.com/cms/asset/100ec8ee-9467-4940-95a4-22349f2802a4/cphc202000552-toc-0001-m.jpg`}
                title="On the Formation of the Popcorn Flavorant 2,3‐Butanedione (CH3COCOCH3) in Acetaldehyde‐Containing Interstellar Ices"
                authors="Dr. N. Fabian Kleimeier  Dr. Andrew M. Turner  Prof. Dr. Ryan C. Fortenberry  Prof. Dr. Ralf I. Kaiser"
                abstract="Acetaldehyde (CH3CHO) is ubiquitous throughout the interstellar medium and has been observed in cold molecular clouds, star forming regions, and in meteorites such as Murchison. As the simplest methyl‐bearing aldehyde, acetaldehyde constitutes a critical precursor to prebiotic molecules such as the sugar deoxyribose and amino acids via the Strecker synthesis. In this study, we reveal the first laboratory detection of 2,3‐butanedione (diacetyl, CH3COCOCH3) – a butter and popcorn flavorant – synthesized within acetaldehyde‐based interstellar analog ices exposed to ionizing radiation at 5 K. Detailed isotopic substitution experiments combined with tunable vacuum ultraviolet (VUV) photoionization of the subliming molecules demonstrate that 2,3‐butanedione is formed predominantly via the barrier‐less radical–radical reaction of two acetyl radicals (CH3ĊO). These processes are of fundamental importance for a detailed understanding of how complex organic molecules (COMs) are synthesized in deep space thus constraining the molecular structures and complexity of molecules forming in extraterrestrial ices containing acetaldehyde through a vigorous galactic cosmic ray driven non‐equilibrium chemistry."
                doi='https://doi.org/10.1002/cphc.202000116'
            />

             <CardFlip
                imageURI={`https://pubs.acs.org/na101/home/literatum/publisher/achs/journals/content/jpcafh/2020/jpcafh.2020.124.issue-16/jpcafh.2020.124.issue-16/20200423/jpcafh.2020.124.issue-16.largecover-2.jpg`} 
                title="Anharmonic Frequencies of (MO)2 and Related Hydrides for M = Mg, Al, Si, P, S, Ca, and Ti and Heuristics for Predicting Anharmonic Corrections of Inorganic Oxides"
                authors="Brent R. Westbrook and Ryan C. Fortenberry*"
                abstract="The low-frequency vibrational fundamentals of D2h inorganic oxides are readily modeled by heuristic scaling factors at fractions of the computational cost compared to explicit anharmonic frequency computations. Oxygen and the other elements in the present study are abundant in geochemical environments and have the potential to aggregate into minerals in planet-forming regions or in the remnants of supernovae. Explicit quartic force field computations at the CCSD(T)-F12b/cc-pVTZ-F12 level of theory generate scaling factors that accurately predict the anharmonic frequencies with an average error of less than 1.0 cm–1 for both the metal–oxygen stretching frequencies and the torsion and antisymmetric stretching frequencies. Inclusion of hydrogen motions is less absolutely accurate but is similarly relatively predictive. The fundamental vibrational frequencies for the seven tetra-atomic inorganic oxides examined presently fall below 876 cm–1 and most of the hydrogenated species do as well. Additionally, ν6 for the SiO dimer is shown to have an intensity of 562 km mol–1, with each of the other molecules having one or more frequencies with intensities greater than 80 km mol–1, again with most in the low-frequency infrared range. These intensities and the frequencies computed in the present study should assist in laboratory characterization and potential interstellar or circumstellar observation."
                doi='https://doi.org/10.1021/acs.jpca.0c01609'
            />
            <CardFlip
                imageURI={`https://pubs.acs.org/na101/home/literatum/publisher/achs/journals/content/jpcafh/2018/jpcafh.2018.122.issue-35/jpcafh.2018.122.issue-35/20180906/jpcafh.2018.122.issue-35.largecover-2.jpg`} 
                title="Rovibrational Considerations for the Monomers and Dimers of Magnesium Hydride and Magnesium Fluoride"
                authors="C. Zachary Palmer and Ryan C. Fortenberry"
                abstract="Magnesium is an understudied chemical element that is quite useful in materials science and may be an essential astrochemical building block for grain formation in proto-planetary disks. This work provides quantum chemical prediction for the vibrational and rovibrational spectra of the structurally similar magnesium hydride and magnesium fluoride monomers and dimers. Magnesium fluoride is commonly utilized in infrared-observing windows and is a known terrestrial mineral, sellaite. Magnesium hydride is likely to exist in various astrophysical environments. Comparison of the anharmonic quantum chemical spectral data computed in this work to known gas phase values for MgH2 is excellent with the computed 1584.1 cm–1 antisymmetric Mg–H stretch less than 5 cm–1 below experiment for example. The condensed phase vibrational attributions of the dimer, however, are less comparable with the present results potentially indicating that some of the previous assignments may need to be revisited. The magnesium fluoride monomer and dimer have no previous vibrational experimental results reported, and the work here should be solid predictions as to their spectral features for comparison either to laboratory work or potentially even to interstellar observations."
                doi='https://doi.org/10.1021/acs.jpca.8b06611'
            />
             <CardFlip
                imageURI={`https://onlinelibrary.wiley.com/cms/asset/d5f016d7-ad76-4902-8d12-1df76877d423/qua25238-toc-0001-m.jpg`} 
                title="Quantum astrochemical spectroscopy"
                authors="Ryan C. Fortenberry"
                abstract="In this review, the origins of astrochemistry and the initial applications of quantum chemistry to the discovery of new molecules in space are discussed. Furthermore, more recent successes and failures of quantum astrochemistry are explored. Finally, the application of quantum chemistry to the chemical study of space is driving developments in large‐scale computational science. Consequently, cloud computing and large molecule computations are discussed. Astrochemistry is a natural application of quantum chemistry. The ability to analyze routinely and completely the structural, spectroscopic, and electronic properties of any given molecule, regardless of its laboratory stability, make this tool a necessary component for astrochemical analysis. The sizes of the computations scaling with the number of electrons and degrees‐of‐freedom can become limiting, but proper choices of methods can provide unique insights. The chemistry of the Earth is a small snapshot of the chemistries available in the universe at large, and the flexibility inherent within computation make quantum chemistry an excellent driver of new knowledge in fundamental molecular science as well as in astrophysics. © 2016 Wiley Periodicals, Inc."
                doi='https://doi.org/10.1002/qua.25180'
            />
            </div>
            <div>
                {publicationView(publications)}
            </div>
        </div>
      )
  }
     
    }
    

export default Publications;
