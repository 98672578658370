import './App.css';
import NavBootstrap from './Nav';
import Home from './routes/Home';
import Publications from './routes/Publications';
import Resources from './routes/Resources';
import Acknowledgements from './routes/Acknowledgements.js';
import Members from './routes/Members';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Footer from './Footer';
import {Helmet} from "react-helmet";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
   <Router>
     <div className="App">
     <Helmet>
                <meta charSet="utf-8" />
                <title>r410 Research Group</title>
      </Helmet>

    <NavBootstrap />
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/members" component={Members} />
      <Route path="/publications" component={Publications} />
      <Route path='/resources' component={Resources} />
      <Route path='/acknowledgements' component={Acknowledgements} />
    </Switch>
    <Footer/>
     </div>
   </Router> 
    
  );
}

export default App;
