import React from 'react';
import '../App.css';
import Figure from 'react-bootstrap/Figure'
import MCSR from "../assets/acknowledgements/mcsr.png"
import CEMOs from '../assets/acknowledgements/cemos.jpg'
import NASA from '../assets/acknowledgements/nasa.png'
import NSF from '../assets/acknowledgements/NSF_logo.png'
import SpaceGrant from '../assets/acknowledgements/spacegrant.png'
import UMiss from '../assets/acknowledgements/umiss.png'


function Acknowledgements() {
    
      return ( 
        <div className="background-container">
            <h1 style={{ fontSize: 32, color: "white", margin: 30}}>Acknowledgements</h1>
        <div className="grid-container-ack">

        <div className="figure-container">
        <Figure>
          <Figure.Image 
            width={300}
            height={75}
            alt="umiss"
            src={UMiss} />
            <Figure.Caption className="caption-text-ack">
              University of Mississippi
            </Figure.Caption>
        </Figure>
        </div>

        <div className="figure-container">
        <Figure>
          <Figure.Image 
            width={300}
            height={75}
            alt="mcsr"
            src={MCSR} />
            <Figure.Caption className="caption-text-ack-ack">
              Mississippi Center for Supercomputing Research
            </Figure.Caption>
        </Figure>
        </div>

        <div className="figure-container">
        <Figure>
          <Figure.Image 
            width={300}
            height={75}
            alt="cemos"
            src={CEMOs} />
            <Figure.Caption className="caption-text-ack">
              Center for Emergent Molecular Optoelectronics
            </Figure.Caption>
        </Figure>
        </div>

        <div className="figure-container">
        <Figure>
          <Figure.Image 
            width={300}
            height={256}
            alt="NASA"
            src={NASA} />
            <Figure.Caption className="caption-text-ack">
              NASA Grant NNX17AH15G
            </Figure.Caption>
        </Figure>
        </div>

        <div className="figure-container">
        <Figure>
          <Figure.Image 
            width={256}
            height={256}
            alt="spacegrant"
            src={SpaceGrant} />
            <Figure.Caption className="caption-text-ack">
              Mississippi Space Grant Consortium
            </Figure.Caption>
        </Figure>
        </div>

        <div className="figure-container">
        <Figure>
          <Figure.Image 
            width={256}
            height={256}
            alt="nsf"
            src={NSF} />
            <Figure.Caption className="caption-text-ack">
              NSF Grant OIA1757220
            </Figure.Caption>
        </Figure>
        </div>
        </div>
        </div>
      )
    }
    

export default Acknowledgements;
