import React from 'react';
import './App.css';
import { Link } from 'react-router-dom';
import GroupLogo from '../src/assets/grouplogo.png'
import { isMobile } from 'react-device-detect';
import { slide as Menu } from 'react-burger-menu';
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';

/*
function Nav() {
    if (isMobile) {
        return (
            <Menu>
                <a id="home" className="menu-item" href="/"> Home </a>
                <a id="members" className="menu-item" href="/members"> Members </a>
                <a id="publications" className="menu-item" href="/publications"> Publications </a>
                <a id="resources" className="menu-item" href="/Resources"> Resources </a>
                <a id="acknowledgements" className="menu-item" href="/acknowledgements"> Acknowledgements </a>
            </Menu>
            )
    } else {

  return (
    <nav>
        <div className="Nav-container">
        <img src={GroupLogo} alt='grouplogo' width='50' height='60' />
        <ul>
            <Link className="link-no-dec" to='/'>
                <li>HOME</li>
            </Link>
            <Link className="link-no-dec" to='/members'>
                <li>MEMBERS</li>
            </Link>
            <Link className="link-no-dec" to='/publications'>
                <li>PUBLICATIONS</li>
            </Link>
            <Link className="link-no-dec" to='/Resources'>
                <li>RESOURCES</li>
            </Link>
            <Link className="link-no-dec" to='/acknowledgements'>
                <li>ACKNOWLEDGEMENTS</li>
            </Link>
        </ul>

        </div>
    </nav>
  );
}
}
*/

function NavBootstrap() {
    return (
        <Navbar bg="410" variant="dark" expand="lg">
        <Container>
                <Navbar.Brand href="#home">
                <img
                    src={GroupLogo}
                    width="50"
                    height="50"
                    className="d-inline-block align-top"
                    alt="React Bootstrap logo"
                />
                </Navbar.Brand>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
                <Nav.Link href="/">Home</Nav.Link>
                <Nav.Link href="/members">Members</Nav.Link>
                <Nav.Link href="/publications">Publications</Nav.Link>
                <Nav.Link href="/resources">Resources</Nav.Link>
                <Nav.Link href="/acknowledgements">Acknowledgements</Nav.Link>
            </Nav>
            </Navbar.Collapse>
        </Container>
        </Navbar>
    )
}
export default NavBootstrap;
