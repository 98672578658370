import React from 'react';
import GitHubImg from './assets/GitHub-Mark.png';
import LinkedInImg from './assets/LinkedIn.jpeg';
import ResearchGateImg from './assets/ResearchGate.png';
import './App.css';
import { isMobile } from 'react-device-detect';

const Footer = () => {
    
    const copyRight = 't'
    const redirectURL = (url) => {
        return window.location.replace(url)
    }

    if (isMobile) {
        return (
            <div className="Footer-container"> 
                <div className="Footer-contact-container">
                <a href="mailto:r410@olemiss.edu">
                    <p>r410@olemiss.edu</p>
                </a>
                <a href="tel:+6629151687">
                    <p>662-915-1687</p>
                </a>
                </div>

                <div className="Footer-social-logos">
                
                <a href="https://www.linkedin.com/in/ryan-fortenberry-345208208/">
                    <img 
                        src={LinkedInImg} 
                        alt="LinkedIn" 
                        height='50em' 
                        width='50em' 
                    />
                </a>
                <a href="https://www.github.com/Awallace3">
                    <img 
                        src={GitHubImg} 
                        alt="GitHub" 
                        height='50em' 
                        width='50em' 
                    />
                </a>
                <a href="https://www.researchgate.net/profile/Ryan-Fortenberry">
                    <img 
                        src={ResearchGateImg} 
                        alt="ResearchGate" 
                        height='50em' 
                        width='50em' 
                    />
                </a>
            </div>

            </div>
        )
    } else {
    return (
        <div className="Footer-container">
            <div className="Footer-contact-container">
                <a href="mailto:r410@olemiss.edu">
                    <p>r410@olemiss.edu</p>
                </a>
                <a href="tel:+6629151687">
                    <p>662-915-1687</p>
                </a>
            </div>
            <div className="Footer-social-logos">
                
                <a href="https://www.linkedin.com/in/ryan-fortenberry-345208208/">
                    <img 
                        src={LinkedInImg} 
                        alt="LinkedIn" 
                        height='50em' 
                        width='50em' 
                    />
                </a>
                <a href="https://www.github.com/Awallace3">
                    <img 
                        src={GitHubImg} 
                        alt="GitHub" 
                        height='50em' 
                        width='50em' 
                    />
                </a>
                <a href="https://www.researchgate.net/profile/Ryan-Fortenberry">
                    <img 
                        src={ResearchGateImg} 
                        alt="ResearchGate" 
                        height='50em' 
                        width='50em' 
                    />
                </a>
            </div>
        </div>
    )
}
}

export default Footer;
