import React from "react";
import "../App.css";
import Bio from "../components/BioTile";
import GroupPhoto from "../assets/GroupPhotos/summer2021.jpg";
import { isMobile } from "react-device-detect";

function Members() {
  const bossman = {
    name: "Ryan C. Fortenberry",
    hometown: "Clinton, MS",
    year_joined: "2018",
    status: "Associate Professor",
    about_me:
      "Ryan Fortenberry is Assistant Professor of Physical Chemistry at the University of Mississippi.  He previously held the same position at Georgia Southern University from 2013 to 2018.  Ryan earned a BS in Mathematics and a MS in Communication from Mississippi College; received a Ph.D. at Virginia Tech in Theoretical Chemistry; and was a NASA Postdoctoral Program Fellow at the NASA Ames Research Center in Mountain View, California.  Ryan is currently the Past-Chair of the ACS Astrochemistry Subdivision and the Ole Miss Campus Coordinator for the Mississippi Space Grant Consortium.  Ryan has over 140 peer-reviewed scientific publications, received several NASA research grants, was selected as the Virginia Tech College of Science 2019-2020 Outstanding Recent Alumni Award, has been published in Scientific American, and is the author of Complete Science Communication, a text on how to write and talk about science both to expert and non-expert audiences alike.  Ryan enjoys the outdoors, travel, and time with family.",
    image: "bossman.jpg",
  };
  const zachPalmer = {
    name: "Zachary Palmer",
    hometown: "Acworth, Georgia",
    year_joined: "Fall 2020",
    status: "Graduate Student",
    about_me:
      "I'm from Acworth, GA and received my B.S. in Chemistry from Georgia Southern University in Statesboro, GA. I'm a current graduate student pursuing a Ph.D. in computational chemistry with an emphasis in Astrochemistry. I have been a member of the Computational Astrochemistry Group @ Ole Miss since the fall of 2020, but have been working with Dr. Fortenberry since the fall of 2017. Since then, my research has led to a first author cover article and a second paper, which I co-authored, on the computation of quartic force fields for molecules of astrochemical importance.",
    image: "zach.jpg",
  };

  const masonGardner = {
    name: "Mason Gardner",
    hometown: "Brookhaven, MS",
    year_joined: "Fall 2019",
    status: "Alumni",
    about_me:
      "My name is Mason Gardner, and I am from Brookhaven, MS. I am a senior pursuing the B.A. in Biochemistry major, and I will be attending medical school in the fall. I have been part of the Astrochemistry group since Fall of 2019, and I have co-authored two published papers, using F12-TZ and CcCR methods to compute Quartic Force Fields for several types of molecules.",
    image: "mason.jpg",
  };

  const olivia = {
    name: "Olivia Ann Harwick",
    hometown: "Mohawk, New York",
    year_joined: "Fall 2020",
    status: "Graduate Student",
    about_me:
      "My academic interests, besides Chemistry, include the intersection of Women’s Rights and the History of Science & Mathematics. I’m also an avid cyclist, and can be found practically every day on my bright orange bicycle. I enjoy walking on nature trails, swimming, and kayaking during the spring and summer, while building snow people is a favorite pastime in winter. I love to travel as well; my photo was taken at Navy Pier in Chicago.",
    image: "olivia.jpg",
  };
  const taylorSantaloci = {
    name: "Taylor J. Santaloci",
    hometown: "Mcdonough, GA",
    year_joined: "2018",
    status: "PhD Candidate",
    about_me:
      "I earned my B.S. in Chemistry (concentration in Biochemistry) from Georgia Southern University where I did experimental research on the synthesis of gold nanorods. I am now a graduate student computational chemist within the Computational Astrochemistry Group @ Ole Miss led by Prof. Ryan Fortenberry. My Ph.D. work here at the University of Mississippi focuses on the photophysical properties of both polycyclic aromatic hydrocarbon (PAH) anions and dye-sensitized solar cells. I am a graduate fellow of the Mississippi Space Grant Consortium. I hope in the future to find work at a government, industry, or academic research lab where I can further my passion for utilizing computational tools to direct new applications of photophysical molecular properties.",
    image: "taylor.png",
  };

  const brent = {
    name: "Brent Westbrook",
    hometown: "Texas",
    year_joined: "2019",
    status: "Graduate Student",
    about_me:
      "Brent Westbrook is currently a second-year graduate student. He obtained his BS in Chemistry from St. Edward’s University in Austin, TX where he did undergraduate research on the formation of covalent bonds to isolated water and hydrogen sulfide molecular cations as well as the molecular dynamics of water in carbon nanotubes. Brent has written numerous computational chemistry programs aimed at unifying high-level anharmonic vibrational frequency approaches into a user-friendly format and hopes to continue to develop software for solving real-world problems after completion of his PhD.",
    image: "brent.jpg",
  };

  const megan = {
    name: "Megan Davis",
    hometown: "Hammond, Lousiana",
    year_joined: "2018",
    status: "Graduate Student",
    about_me:
      "My name is Megan Davis, and I'm from Hammond, Louisiana and received my B.S. in Chemistry from Southeastern Louisiana University. My research focuses on extending quartic force fields to electronically excited states of molecules. My non-academic interests include cooking, music (playing and listening), reading, and video games.",
    image: "megan.jpg",
  };
  const athena = {
    name: "Athena Flint",
    hometown: "Illinois",
    year_joined: "2021",
    status: "Graduate Student",
    about_me:
      "I received my undergraduate training at Yale University, where I graduated in May 2021 with a BS in Chemistry on the Intensive track with a focus in computational chemistry. I am now a graduate student at the University of Mississippi interested in radical-based formation mechanisms of interstellar molecules. Beyond research, I enjoy playing video games, staying caffeinated, and going for rides in my Honda, Stevie Civics (what Stevie Nicks would be named if she were a Transformer).",
    image: "athena.jpeg",
  };

  const ally = {
    name: "Alexandria Watrous",
    hometown: "Lexington, Kentucky",
    year_joined: "Fall 2020",
    status: "Undergraduate",
    about_me:
      "My name is Ally Watrous and I am a freshman pursuing a B.S. Chemistry, B.A. in Physics and minors in Math, French, Japanese, and German. In the future, I plan on going to graduate school for chemistry. When I am not studying, I enjoy going on walks, reading, and drinking coffee.",
    image: "ally.jpg",
  };

  const melanie = {
    name: "Melanie Russell",
    hometown: "Ocean Springs, MS",
    year_joined: "2019",
    status: "Alumni",
    about_me:
      "My name is Melanie Russell, and I’m a graduating senior this year. At the end of this semester, I’ll be receiving a B.S. in Chemistry with an emphasis in Chemical Physics. I’m from Ocean Springs, Mississippi, which is only a few miles away from the Gulf Coast! I love going on scenic walks, playing video games (especially Hades) and hanging out with friends. After I graduate, I’m planning on going back and getting another degree.",
    image: "mel.jpg",
  };

  const michael = {
    name: "Michael Valencia",
    hometown: "Nashville, TN",
    year_joined: "2018",
    status: "Undergraduate",
    about_me: `As an up-and-coming wizard, I have spent much time working for Dr. Fortenberry. My main work consists of minerals in space, developing numerical methods, and website design using Go, Python, and JavaScript. In my free time, I like to play video games, work on my app, and collect playing cards. I am also involved in ASB and Chi Psi.`,
    image: "michael.jpg",
  };

  const austinWallace = {
    name: "Austin Wallace",
    hometown: "Southaven, MS",
    year_joined: "2019",
    status: "Undergraduate",
    about_me:
      "	Working in this research group has sparked my interests in computer science, incentivizing me to learn Python, R, and Javascript. My main projects have been to investigate structures for carbonic acid, develop 0nset, and create a Monte Carlo program merging statistical mechanics and quantum mechanics to characterize ice analogs. My spare time goes towards exercising, gaming, and developing my mobile app. As a former ACE Personal Trainer, I enjoy both weightlifting and running. Finally yes, leg day is the best day.",
    image: "WallaceImage.jpg",
  };

  const jax = {
    name: "Jax Dallas",
    hometown: "Caledonian, MS",
    year_joined: "2019",
    status: "Alumni",
    about_me:
      "Hello all, my name is Jax Dallas and I am pursuing a BS in chemistry with an emphasis in chemical physics and a BA in mathematics. I am a member of the class of 2021, so if you are reading this I am likely a former member of the Fortenberry group at this point. While a member of the group I have investigated the stability of the HeH ion solvated in varying numbers of neon atoms, found the anharmonic vibrational frequencies of complex organic molecules likely to be present in the ISM, and have investigated novel cooling mechanisms for the first stars to ever form in the universe. Following my time at the University of Mississippi I will be pursuing a PhD in chemical physics at the California Institute of Technology.",
    image: "jax.jpg",
  };

  const dev = {
    name: "Dev Patel",
    hometown: "Laurel, MS",
    year_joined: "2021",
    status: "Undergraduate",
    about_me:
      "As a chemistry major who also loves space + computers, Dr. Fortenberry's lab was perfect. Working with him and the group helps me learn new things every day. After graduation, I want to go to either med school or grad school. When I'm not running jobs on maple, I like to code, exercise, work on my app and watch UFC.",
    image: "dev.jpg",
  };
    
  const noah = {
    name: "Noah Garrett",
    hometown: "Madison, MS",
    year_joined: "Spring 2022",
    status: "Undergraduate",
    about_me:
      "My name is Noah, and I am a junior pursuing a BS in chemistry and a BA in mathematics. I am involved with the club soccer team and a member of Beta Theta Pi. I enjoy playing soccer and going to the gym, and in my free time I like to play the drums. Recently my research has consisted of generating spectrsopic data for electron excited states.",
    image: "noah.png",
  };

  const adam = {
    name: "Adam Soltani",
    hometown: "Washington, DC",
    year_joined: "2021",
    status: "Undergraduate",
    about_me:
      "My name is Adam Soltani, a Biochemistry and Arabic double major here at the University of Mississippi. God-willing, I hope to attend medical school and become a primary healthcare physician. Outside of school, I like to read, play basketball, and spend time with my friends.",
    image: "adam.jpeg",
  };
  const jon = {
    name: "Jon Dotson",
    hometown: "Mobile, AL",
    year_joined: "2021",
    status: "Undergraduate",
    about_me:
      "My name is Jon Dotson, and I currently dual-degree in Chemistry and Arabic. A fun fact about me is I work at the Lafayette County Fire Department as a volunteer firefighter. When I'm not in school, my favorite pastimes are working out and playing video games. In the future, I hope to be accepted into an MD/PhD program.",
    image: "jon.png",
  };

  const personObj = {
    name: "",
    hometown: "",
    year_joined: "",
    status: "",
    about_me: "",
    image: "",
  };
  {
    /* Not mobile = desktop version */
  }
  if (!isMobile) {
    return (
      <div className="background-container">
        <h1 style={{ color: "white", margin: 30 }}>
          {" "}
          Fortenberry Research Group Members{" "}
        </h1>
        <img
          className="group-photo"
          src={GroupPhoto}
          alt="groupphoto"
          width="50%"
          height="20%"
        />
        <div>
          <Bio person={bossman} />
        </div>

        <h1 style={{ color: "white", margin: "0" }}> Graduate Students </h1>

        <div className="grid-container-members">
          <div>
            <Bio person={taylorSantaloci} />
          </div>
          <div>
            <Bio person={megan} />
          </div>
          <div>
            <Bio person={zachPalmer} />
          </div>

          <div>
            <Bio person={brent} />
          </div>

          <div>
            <Bio person={olivia} />
          </div>
          <div>
            <Bio person={athena} />
          </div>
        </div>

        <h1 style={{ color: "white" }}> Undergraduate Students </h1>

        <div className="grid-container-members">
          <div>
            <Bio person={ally} />
          </div>
          <div>
            <Bio person={dev} />
          </div>
          <div>
            <Bio person={jon} />
          </div>
          <div>
            <Bio person={noah} />
          </div>
          </div>

        <h1 style={{ color: "white" }}> Alumni </h1>
        <div className="grid-container-members">
          <div>
            <Bio person={melanie} />
          </div>
          <div>
            <Bio person={jax} />
          </div>
          <div>
            <Bio person={masonGardner} />
          </div>
          <div>
            <Bio person={michael} />
          </div>
          <div>
            <Bio person={austinWallace} />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="background-container">
        <h1 style={{ color: "white" }}> Fortenberry Research Group Members </h1>
        <img
          className="group-photo"
          src={GroupPhoto}
          alt="groupphoto"
          width="80%"
          height="20%"
        />
        <div>
          <Bio person={bossman} />
        </div>

        <h1 style={{ color: "white", margin: "0" }}> Graduate Students </h1>

        <div className="grid-container-members-mobile">
          <div>
            <Bio person={taylorSantaloci} />
          </div>
          <div>
            <Bio person={megan} />
          </div>
          <div>
            <Bio person={zachPalmer} />
          </div>
          <div>
            <Bio person={brent} />
          </div>
          <div>
            <Bio person={olivia} />
          </div>
          <div>
            <Bio person={athena} />
          </div>
        </div>

        <h1 style={{ color: "white" }}> Undergraduate Students </h1>

        <div className="grid-container-members-mobile">
          <div>
            <Bio person={ally} />
          </div>
          <div>
            <Bio person={jon} />
          </div>
          <div>
            <Bio person={noah} />
          </div>
        </div>
        <h1 style={{ color: "white" }}> Alumni </h1>
        <div className="grid-container-members-mobile">
          <div>
            <Bio person={melanie} />
          </div>
          <div>
            <Bio person={jax} />
          </div>
          <div>
            <Bio person={masonGardner} />
          </div>
          <div>
            <Bio person={michael} />
          </div>
          <div>
            <Bio person={austinWallace} />
          </div>
        </div>
      </div>
    );
  }
}

export default Members;
